/* You can add global styles to this file, and also import other style files */

@import "assets/theme/procempa/theme.scss";
@import 'primeflex/primeflex.scss';
@import "primeng/resources/primeng.css";


@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

// MAIN LAYOUT (MASTER PAGE)

html {

    height: 100%;

    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;

    min-width: 1000px;

    overflow: auto;
    scrollbar-width: 0;

    body {

        overflow: auto;
        height: 100%;

        // Tipografia geral

        color: $textColor;
        font-size: $fontSize;
        font-weight: $fontWeight;
        font-family: $fontFamily;

    }

}

// RESET SCSS
// Remove estilos pré-definidos de classes do prime-ng

// .p-button, .p-button:hover, .p-button:focus, .p-button:active {
//     all: unset;
// }


.p-dialog {
    box-shadow: none;
    background-color: transparent;

    .p-dialog-header {
        background-color: transparent;
        padding: 0;
    }

    .p-dialog-content {
        background-color: transparent;
        padding: 0;
    }

    .pro-page-section {
        background-color: transparent;
    }

    .p-dialog-header-close {
        display: none;
    }
}

//
// CUSTOMIZAÇÃO PRIME NG
// Customização de estilos do prime NG

.p-datatable .p-datatable-tbody > tr > td {

    vertical-align: middle;

}

.ql-editor {

h4 {
    margin-top: 20px;
    margin-bottom: 10px;
}

}

//
// PRO SCSS
// Estilos gerais a serem utilizados nos sistemas da Procempa (futuramente DS)

.pro-page {

    .pro-page-header {

        @include styleclass('flex flex-wrap');
        @include styleclass('mb-6');

        .pro-title {

            @include styleclass('flex-grow-1 flex-order-1');
            font-size: 42px;
            font-weight: 700;
            color: var(--pro-primary-medium-light);

            small {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                display: block;
            }

        }

        .pro-actions {

            @include styleclass('flex align-items-center flex-order-2');

            *:not(:first-child) {
                @include styleclass('ml-3');
            }

        }

        .pro-navigation {

            @include styleclass('flex flex-order-0');

            flex-basis: 100%;

            .pro-breadcrumbs {
                @include styleclass('flex-grow-1');
                list-style-type: none;

                .pro-breadcrumbs-item {
                    color: var(--pro-primary-medium-light);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 36px;

                    // text-transform: uppercase;
                    display: inline-block;

                    a {
                        color: inherit;
                        text-decoration: inherit;
                        cursor: pointer;
                    }

                    fa-icon {
                        margin-left: 8px;
                        margin-right: 8px;

                        svg {
                            height: 10px;
                            color: var(--pro-neutral-medium-light)
                        }
                    }
                }
            }

            .pro-actions {
                button {
                    font-weight: 400;
                }
            }

        }

    }

    .pro-page-content {

        .pro-page-section {

            @include styleclass('mb-6');

            &.main {
                margin-top: calc(-3rem + 8px);
            }

            .pro-page-section-header {

                @include styleclass('flex align-items-center');


                padding-bottom: 8px;
                border-bottom: 2px solid var(--pro-primary-dark);
                margin-bottom: 28px;

                .pro-title {

                    @include styleclass('flex-grow-1');

                    color: var(--pro-primary-darker);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                }

                .pro-actions {
                    @include styleclass('flex align-items-center');
                }

            }

            .pro-basic-info {
                display: inline-block;
                color: var(--pro-neutral-darker);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                label {
                    &::after {
                        content: ": ";
                    }
                }

                span {
                    font-weight: 700;
                }

                margin-right: 8px;
                border-left: 2px solid var(--pro-neutral-light);
                padding-right: 8px;

                &:first-child {
                    margin-left: 0;
                    border-left: none;
                    padding-left: 0;
                }
            }

        }

        hr {
            @include styleclass('my-6');

            border-bottom: 1px solid #DBDDE5;

            -webkit-box-shadow: 0px 3px 0px 0px rgba(242, 243, 245, 1);
            -moz-box-shadow: 0px 3px 0px 0px rgba(242, 243, 245, 1);
            box-shadow: 0px 3px 0px 0px rgba(242, 243, 245, 1);
        }

    }

}

.pro-panel {

    box-shadow: 0px 6px 10px 0px rgba(9, 30, 66, 0.20), 0px 0px 1px 0px rgba(46, 52, 66, 0.30);
    background: #FFFFFF;

    .p-panel-header {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 28px;

        justify-content: center;

        .p-panel-icons {
            display: none;
        }
    }

    .p-panel-footer {
        border-top: 1px solid var(--pro-neutral-light);
    }

}

.pro-button {

    height: 36px;

    // Variações

    &.highlight {
        @include styleclass('p-button-highlight');
    }

    &.secondary {
        @include styleclass('p-button-secondary');
    }

    &.tertiary {
        @include styleclass('p-button-tertiary');
    }

    &.danger {
        @include styleclass('p-button-danger');
    }

    &.negative {
        @include styleclass('p-button-secondary');
        color: var(--pro-failure) !important;
    }

    // Filhos

    fa-icon {
        margin-right: 8px;
    }

}

.pro-button-icon {

    height: 36px;
    width: 36px;

    @include styleclass('justify-content-center');

    // Variações

    &.highlight {
        @include styleclass('p-button-highlight');
    }

    &.secondary {
        @include styleclass('p-button-secondary');
    }

    &.tertiary {
        @include styleclass('p-button-tertiary');
    }

    &.danger {
        @include styleclass('p-button-danger');

        color: var(--pro-failure) !important;
    }

}

.pro-form {

    @include styleclass('flex flex-row flex-wrap gap-3');
    margin-bottom: 32px;

    .pro-form-item {

        @include styleclass('flex');

        &.required {
            label {
                &::after {
                    content: '*';
                    color: var(--pro-alert);
                    margin-left: 4px;
                }
            }
        }

        &.w-fill {
            @include styleclass('flex-1');
        }

        // Filhos

        label {
            color: var(--pro-neutral-dark);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 8px;

            &.p-checkbox-label {
                margin-bottom: 0;
            }
        }



        input,
        select {
            @include styleclass('w-full');
        }

        .pro-inputtext {
            height: 36px;
        }

        .pro-multiselect {

            width: 100%;

            .p-multiselect {
                width: 100%;
                height: 36px;
                
                align-items: center;

                .p-multiselect-label {
                    font-size: 1rem;
                    line-height: 12px;
                }

                .p-multiselect-trigger {
                    padding-top: 3px;
                }

                &.p-multiselect-chip .p-multiselect-token {
                    color: var(--pro-neutral-dark);
                    background-color: var(--pro-neutral-light);
                }
            }
        }

        .pro-radio-group {
            @include styleclass('flex');
            justify-content: start;
            align-items: center;

            .p-radiobutton.p-component {
                align-items: center;
            }

            .p-radiobutton .p-radiobutton-box:not(.p-disabled)  {
                background: inherit;
                border-color: var(--pro-neutral-dark);
                border: 2px solid;

                width: 14px;
                height: 14px;

                .p-radiobutton-icon {
                    width: 5px;
                    height: 5px;
    
                    background-color: var(--pro-primary);
                }
            }

            .p-radiobutton .p-radiobutton-box.p-disabled {
                background: inherit;
                border-color: var(--pro-neutral-medium-light);
                border: 2px solid;

                width: 14px;
                height: 14px;

                .p-radiobutton-icon {
                    width: 5px;
                    height: 5px;
    
                    background-color: var(--pro-neutral-medium-light);
                }
            }

            .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled) {
                border-color: var(--pro-primary);
            }

            .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
                border-color: var(--pro-primary);
            }
            
            label:not(.label-disabled) {
                flex-grow: 0;
                margin: auto;
                margin-left: 4px;
                margin-right: 12px;

                color: var(--pro-neutral-dark);
            }

            label.label-disabled {
                flex-grow: 0;
                margin: auto;
                margin-left: 4px;
                margin-right: 12px;

                color: var(--pro-neutral-medium-light);
            }
        }

        .p-dropdown.p-component {
            width: 100%;
        }

        .pro-feedback {
            margin-top: 8px;

            &.violation {
                color: var(--pro-alert);
            }
        }

    }

    .pro-form-submit {
        @include styleclass('flex justify-content-end gap-3');
    }

    &.inline {}

    &.not-inline {

        .pro-form-item {
            @include styleclass('flex flex-column');
        }

        .pro-form-submit {
            border-top: 1px solid var(--pro-neutral-light);
            padding-top: 24px;
            @include styleclass('flex justify-content-end gap-3 w-full');
        }

    }

}

.pro-table {

    td {
        vertical-align: middle;
    }

}

.pro-paginator {

    padding-left: 10px;

    .p-paginator {

        padding: 21px 0;

        @include styleclass('flex align-items-center');

        .p-paginator-left-content {
            @include styleclass('flex flex-order-1');
            color: var(--pro-neutral-dark);
        }

        .p-paginator-right-content {
            @include styleclass('flex flex-order-2 align-items-center');
            color: var(--pro-neutral-dark);
            margin-right: 1rem;

            .p-dropdown.p-component {
                height: 36px !important;
                border-color: transparent;

                span {
                    text-transform: uppercase !important;
                }

                .p-dropdown-label {
                    padding-right: 0;
                    line-height: 12px;
                    font-size: 14px !important;
                }
            }
        }

        .p-paginator-prev,
        .p-paginator-next {
            position: relative;
            top: 1px;
            color: var(--pro-primary);
            font-size: 16px;
            height: 1rem;
            min-width: 1.5rem;
            border-radius: 0;

            &:focus {}

            &:hover {
                color: var(--pro-primary-dark);
                background-color: transparent !important;
            }

            &:active {
                color: var(--pro-primary-darker);
                background-color: var(--pro-neutral-lighter);
            }
        }

        .p-paginator-prev {
            @include styleclass('flex flex-order-3');

        }

        .p-paginator-next {
            @include styleclass('flex flex-order-4');
        }

    }

}

//
// REURB SCSS
// Estilos gerais a serem utilizados pelo ReUrb

.alert-tooltip.p-tooltip {
    &.p-tooltip-top {
        padding: 13px 0;

        .p-tooltip-arrow {
            border-top-color: var(--pro-highlight);

            margin-left: -17px;
            border-width: 17px 17px 0;
        }
    }

    &.p-tooltip-bottom {
        padding: 13px 0;

        .p-tooltip-arrow {
            border-bottom-color: var(--pro-highlight);

            margin-left: -17px;
            border-width: 0 17px 17px;
        }
    }

    &.p-tooltip-right {
        padding: 0 13px;

        .p-tooltip-arrow {
            border-right-color: var(--pro-highlight);

            margin-top: -17px;
            border-width: 17px 17px 17px 0;
        }
    }

    &.p-tooltip-left {
        padding: 0 13px;

        .p-tooltip-arrow {
            border-left-color: var(--pro-highlight);

            margin-top: -17px;
            border-width: 17px 0 17px 17px;
        }
    }

    .p-tooltip-text {
        background-color: var(--pro-highlight);
    }
}

.pro-modal-container {
    .pro-panel {
        @include styleclass('flex align-items-center justify-content-center');

        background-color: #FFFFFF;

        @include styleclass('flex flex-row flex-wrap gap-3');
        box-shadow: 0px 6px 10px 0px rgba(9, 30, 66, 0.20), 0px 0px 1px 0px rgba(46, 52, 66, 0.30);
        
        .p-panel-header {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 28px;

            .danger {
                color: var(--pro-alert);
            }
            
        }
    
        .p-panel-footer {
            border-top: 1px solid var(--pro-neutral-light);
        }

        .modal-body {
            @include styleclass('flex flex-column');

            font-weight: 400;
            font-size: 20px;

            span {
                font-weight: 700;
            }

            small {
                display: block;
                margin-top: 24px;
                font-size: 14px;
            }

            .pro-form {
                li {
                    margin-left: 50px;
                }
            }

            .modal-footer {
                border-top: 1px solid var(--pro-neutral-light);
                padding-top: 24px;
                @include styleclass('flex justify-content-end gap-3 w-full');
            }
        }

    }
}