
// PRO DS - Color Variables

$basePure100: #000;

$baseGray90: #091E42;
$baseGray60: #44546F;
$baseGray50: #626F86;
$baseGray20: #B3B9C4;
$baseGray15: #DCDEE4;
$baseGray5: #EEEEEE;

$basePure0: #FFF;

$baseBlue80: #0C2B64;
$baseBlue70: #1B40B0;
$baseBlue50: #557CF2;
$baseBlue40: #6495ED;
$baseBlue30: #91B3F2;
$baseBlue5: #EFF4FD;

$baseOrange60: #B1360D;
$baseOrange40: #F06A40;
$baseOrange15: #FFBCA3;

$baseRed70: #970E29;
$baseRed50: #DC143C;
$baseRed20: #F7A5B6;
$baseRed5: #FEF1F3;

$baseGreen70: #315331;
$baseGreen50: #4D824D;
$baseGreen20: #9EC69E;

// ---------------------------------------



// ---------------------------------------

$proPrimary: $baseBlue40;
$proPrimaryDark: $baseBlue70;

$baseRed50: #DC143C;
$baseOrange15: #FFBCA3;


$proHighlight: $baseRed50;


$primaryColor: $proPrimary !default;
$primaryTextColor: white !default;

$secondaryColor: magenta !default;

$tertiaryColor: cyan !default;

$highlightColor: $proHighlight !default;

//


// PRO-DS Variáveis de Cor

:root {

    --pro-main-font-family: "Noto Sans";
    --pro-main-font-size: 14px;
    --pro-main-font-weight: 400;
    --pro-main-line-height: 16px;

    // Variáveis Primitivas

    --base_pure-100: #000;

    --base_gray-90: #091E42;
    --base_gray-60: #44546F;
    --base_gray-50: #626F86;
    --base_gray-20: #B3B9C4;
    --base_gray-15: #DCDEE4;
    --base_gray-10: #EEEEEE;
    --base_gray-5: #F1F2F4;

    --base_pure-0: #FFF;

    --base_blue-80: #0C2B64;
    --base_blue-70: #1B40B0;
    --base_blue-50: #557CF2;
    --base_blue-40: #6495ED;
    --base_blue-30: #91B3F2;
    --base_blue-5: #EFF4FD;

    --base_orange-60: #B1360D;
    --base_orange-40: #F06A40;
    --base_orange-15: #FFBCA3;

    --base_red-70: #970E29;
    --base_red-50: #DC143C;
    --base_red-20: #F7A5B6;
    --base_red-5: #FEF1F3;

    --base_green-70: #315331;
    --base_green-50: #4D824D;
    --base_green-20: #9EC69E;

    // Variáveis Semânticas 

    // Neutral

    --pro-black: var(--base_pure-100);

    --pro-neutral-darker: var(--base_gray-90);
    --pro-neutral-dark: var(--base_gray-60);
    --pro-neutral: var(--base_gray-50);
    --pro-neutral-medium-light: var(--base_gray-20);
    --pro-neutral-light: var(--base_gray-15);
    --pro-neutral-lighter: var(--base_gray-10);

    --pro-white: var(--base_pure-0);

    // Primary

    --pro-primary-darker: var(--base_blue-80);
    --pro-primary-dark: var(--base_blue-70);
    --pro-primary: var(--base_blue-50);
    --pro-primary-medium-light: var(--base_blue-40);
    --pro-primary-light: var(--base_blue-30);
    --pro-primary-lighter: var(--base_blue-5);

    // Highlight

    --pro-highlight-dark: var(--base_orange-60);
    --pro-highlight: var(--base_orange-40);
    --pro-highlight-light: var(--base_orange-15);

    // Alert

    --pro-alert-dark: var(--base_red-70);
    --pro-alert: var(--base_red-50);
    --pro-alert-light: var(--base_red-20);
    --pro-alert-lighter: var(--base_red-5);

    // Success

    --pro-success-dark: var(--base_green-70);
    --pro-success: var(--base_green-50);
    --pro-success-light: var(--base_green-20);

    // Failure

    --pro-failure-dark: var(--base_red-70);
    --pro-failure: var(--base_red-50);
    --pro-failure-light: var(--base_red-20);
    --pro-failure-lighter: var(--base_red-5);

}

$primaryLightColor: lightgreen !default;
$primaryMediumLightColor: cyan !default;
$primaryDarkColor: purple !default;
$primaryDarkerColor: var(--pro-primary-darker) !default; // red !default;

$highlightBg: $baseOrange15 !default; // red !default;
$highlightTextColor: $primaryDarkerColor !default;


@import 'variables';
@import '../theme-base/components';
@import 'extensions';

// RESET

* {

    padding: 0;
    border: 0;
    margin: 0;
    
    vertical-align: baseline;

}